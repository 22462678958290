

// Property Slider
.property-slider {

    // @include media-breakpoint-up(md) {

        margin-bottom: -150px;
    // }
    @include media-breakpoint-up(lg) {
        margin-bottom: -250px;
    }
    .row{
        max-width: 1250px;
        margin: auto;
    }
    padding: 0px;

    @include media-breakpoint-up(md) {
        padding: 0px 0px 60px 0px;
    }
    .slide-wrapper {

        padding: 0 12px;

        // @include media-breakpoint-up(md) {
        //     padding: 0 15px;
        // }

        text-align: left;

        .prop-img {
            max-width: 360px;
            position: relative;
            max-height: 510px;
            
            .sale-badge {
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 1;
                width: 160px;
            }
            .copy-wrap{
                padding: 0 0px 55px 35px;
                color: #fff;
                position: absolute;
                z-index: 1;
                bottom: 0;
                width: 100%;

                .add{
                    font-family: $headings-font;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 2.2rem;
                    letter-spacing: -0.02em;
                    color: #FFFFFF;
                    line-height: 1.5;
                }
                .summ{
                    font-family: $body-font;
                    font-weight: 500;
                    font-size: 2rem;
                    line-height: 1.2;
                    letter-spacing: 0.02em;
                    color: #7BC9FF;



                }
                .hr{
                    border-bottom: 0.5px solid rgb(185, 182, 182);
                    margin-right: 30px;
                    margin: 30px auto;
                }
                .details{
                    font-weight: 400;
                    font-size: 1.9rem;
                    line-height: 1.2;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                }

            }
        }


    }

    .swiper-button-next,
    .swiper-button-prev {
        position: relative;
        
        margin: 16px 11px;
        min-width: 44px;
        
        @include media-breakpoint-up(md) {
            margin: 0 20px;
            min-width: 40px;
        }

     
    }

    .swiper .swiper-slide p {
        max-width: 100%;
    }

    .swiper-button-next {
        left: 0;
        right: 0;
    }

    .swiper-button-next::after,
    .swiper-button-prev::after {
        font-size: 0 !important;
    }
}
.testimonials {
background: #031964;
position: relative;
    .bg-icon{
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: -80px;
        width: 180px;
        @include media-breakpoint-up(md) {
            bottom: -100px;
            width: 275px;
        }
    }

    
    h2 {
        color: #fff;

        // max-width: 267px;
        @include media-breakpoint-up(md) {
            max-width: 817px;
        }
    }

    .stars-head {
        max-width: 180px;

        @include media-breakpoint-up(md) {
            max-width: 300px;
        }
    }

    .review-slider-wrapper {

        .swiper {
            position: relative;
            overflow: visible;
            padding-bottom: 30px;
            @include media-breakpoint-up(md) {
                overflow: hidden;
                padding-bottom: 100px;
            }
        }

        .swiper-slide {
            &:before {
                content: "";
                border: 2px solid #fff;
                z-index: -1;
                bottom: unset;
                display: block;
                position: absolute;
             
                height: 91%;
                top: 10%;
                width: 92%;
                left: 8%;
                
                @include media-breakpoint-up(md) {
                    height: 104%;
                    width: 92%;
                    top: 5%;
                    left: 8%;
                    
                }
            }

            padding: 15px;

            @include media-breakpoint-up(md) {
                padding: 0 25px;
            }
        }

        .review-wrapper {
            background: #fff;
            position: relative;
            padding: 3rem;


            .review-stars {
                max-width: 120px;
            }

            .quote {
                position: absolute;
                z-index: 2;
                bottom: 20px;
                right: 10px;
            }

            .copy {
                font-weight: 400;
                font-size: 1.6rem;
                line-height: 1.5;
                letter-spacing: 0.02em;
                color: #414142;

                @include media-breakpoint-up(md) {
                    // font-size: 1.8rem;
                }
            }

            .name {
                font-weight: 700;
                font-size: 1.7rem;
                line-height: 1.1;
                letter-spacing: -0.02em;
                color: #000000;

            }

            .title {
                font-weight: 700;
                font-size: 1.2rem;
                line-height: 1.1;
                letter-spacing: -0.02em;
                color: #989898;

     
            }




        }

        .review-button-next,
        .review-button-prev {
            margin: 0 15px;
            width: 45px;

     
        }



        .review-button-prev {
            position: relative;
            z-index: 2;

            @include media-breakpoint-up(md) {
                z-index: 2;
                position: absolute;
                left: 10px;
                top: 50%;
            }
        }

        .review-button-next {
            position: relative;
            z-index: 2;

            @include media-breakpoint-up(md) {
                z-index: 2;

                right: 10px;
                top: 50%;
                position: absolute;

            }
        }
    }
}
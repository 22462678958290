.list-section {
    background: #fff;

    .wrapper {
        position: relative;
        background: #fff;
        padding: 30px 0;
        box-shadow: 0px 23.414px 40.369px rgba(0, 0, 0, 0.05);
        
        @include media-breakpoint-up(md) {
            padding: 40px 0 20px 0;
            // min-height: 390px;
            min-height: 450px;
        }

        p {
            line-height: 1.3;
            font-size: 2rem;
            padding: 0 30px;
            letter-spacing: 0.02em;
        }



        &.img {
            background: none;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            min-height: 334px;

            @include media-breakpoint-up(md) {
                // min-height:390px;
                min-height: 450px;

            }
        }

        .icon {
           img{
            max-height: 100px;
           }
            // width: 100%;
        }

        .num {
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: 10px;
            @include media-breakpoint-up(md) {
                right: 30px;
            }
        }

        h5 {
            line-height: 25px;
            color: #414142;
            font-size: 2.2rem;
            font-weight: 700;
            padding: 0 20px;
            @include media-breakpoint-up(md) {
                padding: 0 20px 0 45px;
                font-size: 2rem;
            }
        }

    }
}
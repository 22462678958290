.lions-share {
  background: #F8F8F8;
  .background-image-container {
    top: 5rem;
  }
  padding-top: 100px;
  padding-bottom: 30px;
  @include media-breakpoint-up(lg) {
    padding-top: 250px;
    padding-bottom: 80px;
  }

  .icon {
    display: flex;
    align-items: flex-end;
    width: 40%;
    padding-bottom: 30px;
 
    @include media-breakpoint-up(md) {
      width: 200px;
      padding-bottom: 0px;

    }

  }

  .icon-center {
    display: flex;
    align-items: end;
    width: 250px;

    @include media-breakpoint-up(md) {
      width: 350px;
    }

  }

  .leaf {
    img {
      max-height: 235px;

      @include media-breakpoint-up(md) {
        max-height: 280px;

      }
    }
  }




}
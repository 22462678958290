.offer {

    .eyebrow {
        padding-top: 15px;
        color: linear-gradient(180deg, #64E381 0%, #40A556 106.76%);
        box-shadow: 0px 20px 30px -20px rgba(0, 104, 255, 0.2);
        font-weight: 500;

        line-height: 1.1;
        font-size: 2rem;
        text-align: center;
        @include media-breakpoint-up(md) {
            font-size: 2.3rem;
        
        }
    }
    .form-wrapper{
        .form-li{
            max-width: 606px;
        }
    }
    strong {
        color: #414142;
        font-weight: 700;
    }

    h2 {
        font-weight: 700;
        letter-spacing: -0.03em;
        text-transform: capitalize;
        color: #0F1132;
        font-size: 3.3rem;

        @include media-breakpoint-up(md) {
            line-height: 1.1;
            font-size: 4.5rem;
        }
    }

    .offer-banner {
        max-width: 900px;
    }

    .check-icon {
        margin-right: 20px;
    }

    .check-copy {
        font-size: 1.9rem;
        line-height: 1.3;
        letter-spacing: 0.02em;
        color: #414142;
        font-family: $body-font;

        strong{
            font-family: $body-font;
        }
        &.end-note{
            margin: 0 !important;
        }
    }

    .btn-section {
        padding: 0;
    }

    .btn,
    button,
    input[type="button"],
    input[type="submit"] {
        max-width: 100%;
    }
}
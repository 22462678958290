body.ebook-page {

    .container {
        max-width: 1240px;
    }

    .single-col {
        padding-bottom: 30px;
        padding-top: 50px;

        @include media-breakpoint-up(md) {
            padding: 60px 0;
        }
    }

    .check-icon {
        margin-right: 20px;
    }

    .check-copy.end-note {
        margin: 0 !important;
    }

    .column-check-list {
        padding-bottom: 30px;
        padding-top: 50px;

        @include media-breakpoint-up(md) {
            padding: 60px 0;
        }

        background: linear-gradient(180deg, #F8F8F8 0%, rgba(241, 241, 241, 0) 100%);

        .icon {
            height: 15px;
            width: 15px;
        }
    }

    .ebook-cover {
        // padding: 10px;

        // @include media-breakpoint-up(lg) {
        //     padding: 0px;
        // }
        img {
            transform: scale(.9);
            @include media-breakpoint-up(xl) {
                transform: scale(1.1);
            }
        }
    }

    .ebook-header {
        padding-top: 80px;
        padding-bottom: 0px;

        @include media-breakpoint-up(md) {
            padding-top: 120px;
            padding-bottom: 60px;

        }

        @include media-breakpoint-up(lg) {
            padding-top: 180px;
            padding-bottom: 60px;
        }

        h1 {
            // max-width: 281px;
            // text-align: center;
            margin: auto;
            font-size: 25px;
            letter-spacing: -0.03em;

            @include media-breakpoint-up(sm) {
                margin: 0;
                text-align: left;
                // max-width: 572px;
                font-size: 46px;
            }
        }

      

        .btn-section {
            margin: auto;
            @include media-breakpoint-up(lg) {

                margin: 0;
            }
            max-width: 578px;
        }
    }

    .col-mw{
        max-width: 578px;
    }

    .text-box{
        p{
            line-height: 1.4;
            letter-spacing: 0.02em;
            font-size: 2rem;
            @include media-breakpoint-up(md) {
                font-size: 2.2rem;
            }
        }
    }
    .ebook-footer {
        color: #FFFFFF;
    }
    .footer{
        background: #fff;
        padding: 20px 0;

        @include media-breakpoint-up(md) {
            padding: 50px 0;
        }
        background: none;
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        p{
            color: #000;
            a{
                text-decoration: none;
                color: #000;
                transition: 0.3s;
                &:hover{
                    color: #000;
                    text-decoration: underline;
                }
            }
    
        }
        .logo{
            img{
                max-width: 150px;
            }
        }
    }

}
form {

    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {
        width: 100%;
        border: 1px solid #EFEFEF;
        box-shadow: 30px 26px 40px -20px rgba(153, 153, 153, 0.08);
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 1.6rem;
        line-height: 1.2;
        color: #414142;
        opacity: 1 !important;

        padding: 22px 15px 20px 35px;
        @include media-breakpoint-up(md) {
            padding: 20px 15px 20px 60px;
            font-size: 2rem;

        }

        @include media-breakpoint-up(lg) {
            padding: 20px 15px 20px 70px;
            font-size: 2rem;

        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
    }

    ::-webkit-input-placeholder {
        color: #414142;
        opacity: 1;
    }

    ::-moz-placeholder {
        color: #414142;
        opacity: 1;

    }

    :-ms-input-placeholder {

        opacity: 1;
        color: #414142;
    }

    :-moz-placeholder {
        opacity: 1;
        color: #414142;
    }


    textarea {
        height: 210px !important;
    }


    select {
        width: 100%;
        border: 1px solid #EFEFEF;
        box-shadow: 30px 26px 40px -20px rgba(153, 153, 153, 0.08);
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 1.6rem;
        color: #414142 !important;
        background: #fff;
        opacity: 1;
        appearance: none;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='14' viewBox='0 0 24 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L12 11L22 2' stroke='%23272727' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 15px auto;

        overflow: hidden;
        white-space: pre;
        text-overflow: ellipsis;
        line-height: 1.4;

        padding: 22px 15px 20px 35px;

        @include media-breakpoint-up(sm) {
            padding: 20px 15px 20px 40px;
            font-size: 2rem;


        }

        @include media-breakpoint-up(md) {
            padding: 20px 15px 20px 60px;
            font-size: 2rem;

        }

        @include media-breakpoint-up(lg) {
            padding: 20px 15px 20px 70px;
            font-size: 2rem;

        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }


    }

    select {
        appearance: none;
    }

    .form-content {
        max-width: 578px;
        margin: auto;
    }

    .form-group {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        line-height: 1;

        .icon {
            position: absolute;
            top: 23px;
            left: 10px;
            width: 15px;
            height: 15px;
            z-index: 999;

            @include media-breakpoint-up(md) {
                width: 20px;
                height: 20px;
                left: 25px;
            }
        }
    }

    label.error,
    .field-error {
        display: block;
        margin-bottom: 20px;
        margin-top: -10px;
        color: red;
        font-size: 1.5rem;
    }

    .form-messages {
        text-align: center;

        &.success {
            color: green;
        }

        &.error {
            color: red;
        }
    }
}
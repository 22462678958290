// --------------------
// Include Fonts Here
// --------------------
// @font-face {
//     font-family: 'Averta';
//     src: url('../fonts/Averta-Regular.woff2') format('woff2'),
//     url('../fonts/Averta-Regular.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'Averta';
//     src: url('../fonts/Averta-Bold.woff2') format('woff2'),
//     url('../fonts/Averta-Bold.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;
// }

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins-Bold.woff2') format('woff2'),
        url('../fonts/Poppins-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-400.woff2') format('woff2'),
        url('../fonts/Roboto-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF-Pro-Display';
    src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


$headings-font: 'Poppins',
sans-serif;
$body-font: 'Roboto',
sans-serif;

// --------------------
//  Include Basic Typography
// --------------------

:root {
    font-size: 50%; //1rem = 8px

    @include media-breakpoint-up(md) {
        font-size: 56.25%; //1rem = 9px
    }

    @include media-breakpoint-up(lg) {
        font-size: 62.5%; // 1rem = 10px
    }
}

html,
body {
    @include font-smoothing('on');
    font-family: $body-font;
    letter-spacing: 0.02em;
    color: #414142;
    line-height: 1.5;
    font-size: 2rem;
    @include media-breakpoint-up(md) {
        font-size: 1.9rem;
    }
}

p {
    margin-bottom: 1.6rem;
}

// Headline padding
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    line-height: 1.2;
    margin-bottom: 2rem;
    font-family: $headings-font;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 2.8rem;

    @include media-breakpoint-up(md) {
        font-size: 4.6rem;
    }
}

h1,
.h1 {
    color: #FFFFFF;
    font-size: 3.7rem;
    line-height: 1.2;

    @include media-breakpoint-up(md) {
        font-size: 4.6rem;
        line-height: 1.1;
        letter-spacing: -0.03em;
    }
}

h2,
.h2 {
    color: #0F1132;
    font-size: 3.7rem;
    line-height: 1.3;
    letter-spacing: -0.03em;
    @include media-breakpoint-up(md) {
        font-size: 3.8rem;
        line-height: 1.1;
    }

}

h3,
.h3 {
    font-size: 3rem; //Laptop: 30px, Ipads: 27px, Mobiles: 24px
}

h4,
.h4 {
    font-size: 2.4rem; //Laptop: 24px, Ipads: 21.6px, Mobiles: 19.2px
}

h5,
.h5 {
    font-size: 2rem; //Laptop: 20px, Ipads: 18px, Mobiles: 16px
    font-family: $body-font;
}

h6,
.h6 {
    font-size: 1.8rem; //Laptop: 18px, Ipads: 16.2px, Mobiles: 14.4px
}

strong {
    font-family: $headings-font;
}

// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    font-family: $body-font;
    color: rgba(100, 227, 129, 1);
    font-size: 2rem;
    line-height: 1.2;
    background: linear-gradient(180deg, #64E381 0%, #40A556 106.76%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.05em;
    text-shadow: 0px 20px 30px rgba(0, 104, 255, 0.2);
    font-weight: 500;
    // @include media-breakpoint-up(md) {
    //     font-size: 2rem;
    // }
}

.subheadline {
    color: #FFFFFF;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.3;
}

.wht {
    color: #FFFFFF;
}

footer {
    padding: 20px 0;

    @include media-breakpoint-up(md) {
        padding: 50px 0;
    }
    background: none;
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    p{
        color: #fff;
        a{
            text-decoration: none;
            color: #fff;
            transition: 0.3s;
            &:hover{
                color: #fff;
                text-decoration: underline;
            }
        }

    }
    .logo{
        img{
            max-width: 150px;
        }
    }
}
.icon-steps {
    // .bg-img-wrap {
    //     position: absolute;
    //     z-index: -1;

    //     left: 0;
    //     overflow: hidden;
    //     pointer-events: none;
    //     top: 10%;
    //     width: 100%;

    // }

    .block-parent {
        max-width: 780px;

        .block-wrapper {
            background: #FFFFFF;
            box-shadow: 0px 23.414px 40.369px rgba(0, 0, 0, 0.05);
            margin-bottom: 40px;

            @include media-breakpoint-up(md) {
                margin-bottom: 30px;
            }

            .icon {
                img {
                    border-radius: 100%;
                    box-shadow: 1px 1px 8px rgba(0, 0, 0, .2);
                }

                position: relative;
                left: 0px;
                top: -20px;
                width: 50px;

                @include media-breakpoint-up(md) {
                    left: -45%;
                    position: relative;
                    top: 0;
                    width: 70px;
                }
            }

            .copy {
                padding: 15px 20px 20px 20px;
                margin-top: -15px;
                margin-left: 0;
                font-size: 1.9rem;
                line-height: 1.2;
                
                strong{
                    font-size: 2.1rem;
                    font-family: $body-font;
                }
                @include media-breakpoint-up(md) {
                    padding: 35px 20px 35px 0;
                    margin-top: 0;
                    margin-left: -8px;

                }
            }
        }
    }
}
.magic-number {


    .intro-section {

        .profile-wrapper {
            position: relative;

            @include media-breakpoint-up(md) {

            }

            .sig-wrap {
                margin: -7.5rem auto 4rem auto;
                position: relative;
                background: #FFFFFF;
                padding: 2rem;
                // box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.05);
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                max-width: 320px;
                
                @include media-breakpoint-up(md) {
                    max-width: 460px;
            }
                .name {
                    letter-spacing: -0.03em;
                    color: #1D9FE8;
                    font-size: 2.3rem;
                    font-family: $headings-font;
                }

                .title {
                    font-family: $body-font;
                    color: #031964;
                    font-size: 1.9rem;
                    line-height: 1.3;
                }



            }
        }
    }
    .hatch-background{
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: -1;
        left: 0;
    }
    // .hatch-bg-right{
    //     height: 100%;
    //     pointer-events: none;
    //     position: absolute;
    //     width: 100%;
    //     z-index: -1;
    //     max-height: 480px;
    //     bottom: 0;
    //     max-height: 480px;
    //     overflow: hidden;
    // }
    // .hatch-bg-center{
    //     height: 100%;
    //     pointer-events: none;
    //     position: absolute;
    //     width: 100%;
    //     z-index: -1;
    //     left: 0;
    // }
}
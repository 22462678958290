.flip-clock {
	text-align: center;
	perspective: 800px;
	margin: 20px auto;
	
	&__piece {
		display: inline-block;
		margin: 0 5px;
	}

	&__slot {
		font-size: 2vw;
	}
}	

.card {
	display: block;
	position: relative; 
	padding-bottom: 0.72em;
	font-size: 5rem;
	line-height: 0.95;
	&__top,
	&__bottom,
	&__back::before,
	&__back::after {
		display: block;
		height: 0.72em;
		color: #ccc;
		background: #222;
		padding: 0.25em 2rem;
		border-radius: 8px 8px 0 0;
		backface-visiblity: hidden;
		transform-style: preserve-3d;
		width: 10rem;
		transform: translateZ(0);
	}
	
	&__bottom { 
		color: #FFF;
		position: absolute;
		top: 50%;
		left: 0;
		border-top: solid 1px #000;
		background: #393939; 
		border-radius: 0 0 8px 8px; 
		pointer-events: none;
		overflow: hidden;
	}
	
	&__bottom::after {
		display: block;
		margin-top: -0.72em;
	}
	
	&__back::before,
	&__bottom::after {
		content: attr(data-value);
	}
	
	&__back {
		position: absolute;
		top: 0;
		height: 100%;
		left: 0%;
		pointer-events: none;
	}
	
	&__back::before {
		position: relative;
		z-index: -1;
		overflow: hidden;
	}
}

.flip {
	.card__back {
		&::before {
			animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
			animation-fill-mode: both;
			transform-origin: center bottom;
		}
		
		.card__bottom {
			transform-origin: center top;
			animation-fill-mode: both;
			animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);// 0.3s; 
		}
	}
}

@keyframes flipTop {
	0% {
		transform: rotateX(0deg);
		z-index: 2;
	}
	0%, 99% {
		opacity: 0.99;
	}
	100% {
		transform: rotateX(-90deg);
		opacity: 0;
	}
}

@keyframes flipBottom {
	0%, 50% {
		z-index: -1;
		transform: rotateX(90deg);
		opacity: 0;
	}
	51% {
		opacity: 0.99;
	}
	100% {
		opacity: 0.99;
		transform: rotateX(0deg);
		z-index: 5;
	}
}
.single-col{
    &.guar-section{
        background: linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
    }
    &.bl-bg{
        background: #031964;
        position: relative;
        .bg-icon{
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: -80px;
            width: 180px;
            @include media-breakpoint-up(md) {
                bottom: -100px;
                width: 275px;
            }
        }
    }

    .guar-icon{
        max-width: 230px;
    }
}
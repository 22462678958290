body.thank-you {
    #hero {
        min-height: 95vh;
    }
}
body.thank-you-booking {
    #hero {
        min-height: 95vh;
    }
}
.calendly-inline-widget{
    height: 720px;
    margin: 1rem 0 3rem;

    @include media-breakpoint-up(xl){
        position: relative;
        margin: -50px 0 0px;
    }
}
// .bg-img-wrap{
//     position: absolute;
//     z-index: -1;
// }
.sharpen {
    image-rendering: -webkit-optimize-contrast;
}

.hero-centered {

    padding: 100px 0 20px 0;

    @include media-breakpoint-up(md) {
        padding: 160px 0px 80px 0px;
    }

    h1 {
        max-width: 1000px;
    }

    p {
        // max-width: 725px;
    }

    .btn-primary {
        max-width: 578px;

    }
}

.p-max {
    @include media-breakpoint-up(md) {
        max-width: 518px;
        padding: 0px;
    }
}

.underline {
    box-shadow: inset 0 0px 0 white, inset 0 -4px 0 #E6B228;

    @include media-breakpoint-up(md) {
        box-shadow: inset 0 0px 0 white, inset 0 -7px 0 #E6B228;
    }
}


.blk {
    color: #353535;
}

.wht {
    color: #fff !important;
}


.blu {
    color: #031964;
}

.highlight-dark {
    background-image: url(../images/bg-dark.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 80%;
    padding: 0 .5%;
}

.highlight-light {
    background-image: url(../images/bg-lgt.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 80%;
    padding: 0 .5%;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

// ICONS
.icons {
    padding: 0 !important;
    padding-top: 0 !important;
    box-shadow: 0px 52px 80px 25px rgba(164, 188, 188, 0.1);

    .logo-wrap {

        margin: 10px 0;

        @include media-breakpoint-up(md) {
            margin: 30px 0;
        }

        .logo {
            margin: 15px 0;

            @include media-breakpoint-up(md) {
                img {
                    max-height: 80px;
                }
            }

        }
    }

}

.center-bg-hatch {
    background: #F8F8F8;
    position: relative;

    img {
        position: absolute;
        z-index: 1;
        &.extra-pad{
            padding-top: 200px;
        }
    }
}

.bg-overlay {
    position: relative;
    z-index: 2;
}
.bg-overlay-right{

}
.center-bg-hatch-right {
    // background: #F8F8F8;
    position: absolute;

    z-index: 1;
    img {
        // border: 1px red solid;
        // position: absolute;
        // z-index: 1;
        &.extra-pad{
            padding-top: 200px;
        }
    }
}

.CALEDNLY{
    border: 1px red solid;
}
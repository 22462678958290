// --------------------
// BUTTON STYLE
//---------------------

.btn,
button,
input[type=submit],
input[type=button] {
    font-family: $headings-font;
    letter-spacing: 0.02em;
    border: 0;
    margin-bottom: 12px;
    text-transform: uppercase;
    white-space: normal;
    color: #fff;
    text-shadow: 0px 3.4px 3.4px rgba(0, 0, 0, 0.25);
    padding: 20px;
    width: 100%;
    font-weight: 700;
    transition: 0.3s;

    font-size: 1.8rem;
    line-height: 1.4;;
    
    @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 1.4;
        padding: 25px 20px;
    }

    &:hover {
        color: #fff;
    }

    &:focus,
    &:active {
        color: #fff;
    }
}

.btn-primary {
    position: relative;
    z-index: 9;
    display: inline-block;
    background: linear-gradient(180deg, #64E381 0%, #40A556 106.76%);
    // box-shadow: 0px 20px 30px -20px rgba(0, 104, 255, 0.2);
    
}
.form-btn{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(rgba(123, 201, 255, 1) 0%, #031964 100%);
    background: rgba(123, 201, 255, 1);
    transition: opacity .4s linear;
    
}

.btn-primary-hover {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(rgba(123, 201, 255, 1) 0%, #031964 100%);
    background: rgba(123, 201, 255, 1);
    transition: opacity .4s linear;
}

.btn-primary:hover .btn-primary-hover {
    opacity: 1;
}
.btn-section {
    // max-width:  578px;
    margin: auto;
    p {
        width: 95%;
        line-height: 1.2;
        font-size: 1.6rem;
        margin: 0 auto;
    }
}
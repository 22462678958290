body.modal-open {
    height: 100vh;
    overflow-y: hidden;
    .modal {
		h2{
			line-height: 1;
			max-width: 487px;
            padding-top: 40px;
		}
		p{
			padding: 0;
            // @include media-breakpoint-up(md) {
			// 	padding: 0 50px 0 50px;
			// }
		}
        background: rgba(0, 0, 0, .8);
        .modal-dialog,
        .modal-content {
            width: 95%;
            margin: auto;

            @include media-breakpoint-up(md) {
				width: 100%;
				max-width: 582px;
				max-height: 702px;
            }

            .modal-header {
                border-bottom: none !important;
                font-family: $headings-font;
                font-weight: 700;
                font-size: 3rem;

                color: #053333;
                padding: 10px 10px;

                @include media-breakpoint-up(md) {
                    font-size: 3.4rem;
                    padding: 50px 50px 0 50px;
                }
            }
        }

        .modal-body {
            padding: 10px;

            @include media-breakpoint-up(md) {
                padding: 20px 50px 50px 50px;
            }

            .form-container {
                .btn-section {
                    .btn {
						padding: 10px 50px;
                        font-size: 1.9rem;
						@include media-breakpoint-up(md) {
                        	padding: 30px;
                            font-size: 1.7rem;
						}
                    }
                }
            }
        }

        .close {
            // top: 10px;
            // right: 10px;
            // z-index: 999;
            // width: 30px;
            // height: 30px;
            // text-align: center;
            // background-color: white;
            // border-radius: 50%;
            right: 0;
        }
    }
    input[type=text],
    input[type=email] {
		width: 100%;
        padding: 20px 15px 20px 50px;
        border: 1px solid #EFEFEF;
        box-shadow: 30px 26px 40px -20px rgba(153, 153, 153, 0.08);
         // box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
        // border: none;
        margin-bottom: 15px;
        border-radius: 5px;
        font-size: 2rem;
        line-height: 1.2;

        @include media-breakpoint-up(md) {
			padding: 20px 15px 20px 70px;
        }

        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
    }

}
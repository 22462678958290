header {
    padding: 15px;

    @include media-breakpoint-up(md) {
        padding: 35px;
    }

    background-color: transparent;
    &.scrolled {
        padding: 15px;
        box-shadow: -3px -3px 6px 8px rgba(0,0,0,.05);
        background-color: $headerbg;
        // transition: 0.2s ease-in-out;
    }
}

.header-logo img {
    transition: 0.2s ease-in-out;
    // Inverts the logo colour. You could use the filter options to modify a transparent png logo for the different colour header bg.
    // filter: invert(1);

    // Default to small scrolled size on mobile 
    width: $header-scrolled-width;

    // Turn on the header resize on desktop
    @include media-breakpoint-up(md) {
        width: $header-starting-width;
    }
    .scrolled & {
        // filter: none;
        // Turn on the header resize on desktop
        @include media-breakpoint-up(md) {
            width: $header-scrolled-width;
        }
    }
}

.header-phone {
    line-height: 1.2; 
    color: #fff;
    transition: 0.3s;
    text-decoration: none;
    &:hover{
        color: rgba(100, 227, 129, 1);
    }
}
    a{
    .phone-icon {
        img,
        svg {
            max-width: 40px;
            width: 100%;
            margin-right: 5px;
        }
    }

    // Got questions (if it exists)
    span {
        font-size: 1.6rem;
        line-height: 1.1;
    }
    // Phone number text
    em {
        font-family: $headings-font;
        font-size: 2.6rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.6;
    }


} 